<template>
  <v-col cols="12" sm="12" md="6">
    <slot name="title"></slot>
    <br />
    <br />
    <v-row>
      <v-col cols="12" md="7" v-if="photo_url">
        <img class="img_detail" :src="photo_url" alt="" />
      </v-col>
      <v-col cols="12" md="7" v-else>
        <v-img
          class="img_detail"
          src="@/assets/images/person.jpg"
          alt=""
        />
      </v-col>
      <v-col cols="12" md="3" class="button-picture">
        <label class="custom-file-upload">
          <input type="file" @change="handleImage($event)" accept="image/*" />
          Subir Foto
        </label>
        <!-- <v-file-input
          hide-input
          accept="image/*"
          @change="handleImage($event)"
          label="Foto"
        ></v-file-input> -->
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "foto-upload",
  props: ["photo_url"],
  watch: {
    error: function (value) {
      console.log(value);
      //   if (value) this.mensaje(true, "error", this.error.errorMessage, 5000);
    },
  },
  computed: {
    ...mapState({
      fetchingData: (state) => state.fetchingData,
      error: (state) => state.error,
    }),
  },
  data: () => ({}),
  methods: {
    ...mapActions(["uploadImage"]),
    handleImage(e) {
      if (e) {
        let fileToReturn= e.target.files[0];
        const formData = new FormData();
        formData.append("image", fileToReturn);
        this.uploadImage({ data: formData });
      }
    },
    base64ToFile(data, filename) {
      const arr = data.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, {
        type: mime,
      });
    },
  },
};
</script>

<style scoped>
.img_detail {
  width: 400px;
  height: 300px;
}
.button-picture {
  display: flex;
  flex-direction: row;
  align-items: end;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  width: 190px;
  height: 52px;
  background-color: #479b25;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  font-weight: bold;
}
</style>
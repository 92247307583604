<template>
  <v-col cols="12" sm="12" md="12">
    <slot name="title"></slot>
    <br />
    <br />

    <v-col cols="12" md="1">
      <v-file-input
        hide-input
        accept="application/pdf"
        @change="handleFile($event)"
        label="Files"
      ></v-file-input>
    </v-col>

    <v-list-item
      v-for="(file, index) in files"
      :key="index"
      class="files-items"
      v-show ="file.operation != 'delete'"
    >
      <v-list-item-avatar class="file-avatar">
          <v-icon class="green lighten-1" dark> mdi-check-bold </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            <a :href="file.file_url" class="" target="_blank">{{
              file.name
            }}</a>
          </v-list-item-title>

          <v-list-item-subtitle>
            {{ getDateFormat(file.createdAt) }}</v-list-item-subtitle
          >
        </v-list-item-content>

        <v-list-item-action>
          <v-btn icon>
            <v-icon color="grey lighten-1" @click="deleteItem(file)"
              >mdi-close-box</v-icon
            >
          </v-btn>
        </v-list-item-action>
    </v-list-item>
  </v-col>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Files",
  props: ["files","module"],
  watch: {
    error: function (value) {
      console.log(value);
    },
  },
  computed: {
    ...mapState({
      fetchingData: (state) => state.fetchingData,
      error: (state) => state.error,
    }),
  },
  methods: {
    ...mapActions(["uploadFile", "deleteFile"]),
    deleteItem(item) {
      this.deleteFile({ file: item, module: this.module });
    },
    handleFile(e) {
      if (e) {
        const formData = new FormData();
        formData.append("file", e);
        this.uploadFile({ data: formData, module: this.module });
      }
    },
  },
};
</script>

<style scoped>
.files-items {
  border-radius: 5px;
  border: 2px solid #d3d3d3;
  margin: 2px;
}
.file-avatar {
  height: 30px !important;
  width: 30px !important;
}
</style>
<template>
  <v-container fluid class="ml-10">
    <br /><br />
    <v-row dense id="rowAddEdit">
      <v-col cols="12">
        <h1 class="mr-8" v-if="id == null">
          <v-btn fab dark small color="primary" @click="$router.go(-1)">
            <v-icon>mdi-keyboard-return</v-icon>
          </v-btn>
          Agregar beneficiario
        </h1>

        <h1 class="mr-8" v-if="id != null">
          <v-btn fab dark small color="primary" @click="$router.go(-1)">
            <v-icon>mdi-keyboard-return</v-icon>
          </v-btn>
          Editar beneficiario
        </h1>
      </v-col>

      <v-col cols="12">
        <br /><br />
        <form-associated :associated="iassociated"></form-associated>
      </v-col>

      <v-col cols="12" class="text-center">
        <br />
        <br />
        <v-btn
          class="button-save"
          large
          color="primary"
          dark
          dense
          @click="save()"
        >
          Guardar
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="color" class="mr-2" top>
      <span class="d-flex justify-center white--text">{{ text }}</span>
    </v-snackbar>
  </v-container>
</template>

<script>
import FormAssociated from "@/components/forms/FormAssociated.vue";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "AssociatedAddEdit",
  props: ["id", "membership_id"],
  components: { FormAssociated },
  watch: {
    associatedSuccessMessage: function (value) {
      if (value)
        this.mensaje(true, "success", this.associatedSuccessMessage, 5000);
      if (!this.id) this.iassociated = {};
      this.$router.go(-1);
    },
    associatedError: function (value) {
      if (value)
        this.mensaje(true, "error", this.associatedError.errorMessage, 5000);
    },
    errorMaxFotos: function (value) {
      if (value) this.mensaje(true, "error", value, 5000);
    },
    associated: function (value) {
        if(this.id){
          this.iassociated = Object.assign({}, value);
        }else{
          this.iassociated = Object.assign({...this.iassociated},{...value})
        }
    },
    photo_url: function (value) {
      this.iassociated = Object.assign(
        {},
        { ...this.iassociated, photo_url: value }
      );
    },
    // fileAdd(value) {
    //   this.iassociated = Object.assign(this.iassociated, {...value});
    // },
    fileDelete(value) {
      this.iassociated = Object.assign({}, this.associated);
    },
  },
  computed: {
    ...mapState({
      errorMaxFotos: (state) => state.util.errorMaxFotos,
      // fetchingData: (state) => state.fetchingData,
      associatedError: (state) => state.associated.associatedError,
      associatedSuccessMessage: (state) =>
        state.associated.associatedSuccessMessage,
      associated: (state) => state.associated.associated,
      photo_url: (state) => state.util.photo_url,
      fileDelete: (state) => state.util.fileDelete,
      // fileAdd : (state)  => state.util.fileAdd,
    }),
  },
  data: () => ({
    iassociated: {
      // names:"socio",
      // last_name: "30",
      // document_id:1,
      // document_number: "72391030",
      // birth_date: "1996-11-21",
      // phone: "940393755",
      // address:"Ventanilla",
      // reference: "Costado municipalidad",
      // email: "socio14@startapps.com.pe"
    },
    snackbar: false,
    color: "",
    text: "",
  }),
  methods: {
    ...mapActions(["addAssociated", "editAssociated", "getAssociatedById"]),
    save() {
      if (this.id) {
        //update
        this.editAssociated({ associated: this.iassociated });
      } else {
        this.iassociated.membership_id = this.membership_id;
        this.addAssociated({ associated: this.iassociated });
        //create
      }
    },
    mensaje(snackbar, color, text) {
      this.snackbar = snackbar;
      this.color = color;
      this.text = text;
    },
    getFetch() {
      if (this.id) {
        this.getAssociatedById({ id: this.id });
      }
    },
  },
  created() {
    this.getFetch();
  },
};
</script>


<style scoped>
#rowAddEdit {
  padding-right: 80px !important;
}
</style>
